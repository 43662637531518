import React, { useEffect, useState } from "react";
import axios from "axios";
import QRCode from "qrcode.react";

const QPayPage = () => {
  const [qrText, setQrText] = useState("");
  const [qrShortUrl, setQrShortUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQRCode = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const submissionId = urlParams.get("submissionId");
        console.log("Submission ID:", submissionId);

        const response = await axios.post(
          "https://human-python-backend-4d5fd681de29.herokuapp.com/createInvoice",
          {
            amount: 60000,
            phoneNumber: 99999999,
            type: "ADMISSIONFEE",
            email: "test@gmail.com",
            subscriberId: submissionId,
          }
        );

        //console.log(response.data);

        setQrText(response.data.qr_text);
        setQrShortUrl(response.data.qPay_shortUrl);
        setLoading(false); // Set loading to false after receiving responses
      } catch (error) {
        console.error("Error fetching QR code:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchQRCode();
  }, []); // empty dependency array to run the effect only once

  return (
    <div
      style={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100vh",
      }}
    >
      {loading ? (
        <p>Төлбөрийн QR үүсгэж байгаа тул түр хүлээгээрэй...</p>
      ) : (
        <>
          <strong>
            <h1>ӨРГӨДЛИЙН ХУРААМЖ ТӨЛӨХ</h1>
          </strong>
          <br />
          <p>
            Тус өргөдлийн хураамж амжилттай төлөгдсөн цаг таны өргөдөл илгээгдэх
            болно.
          </p>
          <p>
            Та тус QR кодыг дурын банкны апп-аар уншуулан төлбөрөө төлөөрэй.
          </p>
          <br />
          {qrText && <QRCode value={qrText} size={200} />}
          <br />
          <p>Дүн: ₮60,000</p>
          <br />
          <a href={qrShortUrl} target="_blank" rel="noopener noreferrer">
            <strong>
              <p>Эсвэл та дарж банкны апп-руугаа үсрээрэй.</p>
            </strong>
          </a>
        </>
      )}
    </div>
  );
};

export default QPayPage;
