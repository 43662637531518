import React, { useEffect, useState } from "react";
import { Text } from "components";
import { useParams } from "react-router-dom";
import QRCode from "qrcode.react";
import axios from "axios";

const Schools = () => {
  const { id } = useParams();
  const [submissionID, setSubmissionID] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [qrText, setQrText] = useState("");
  const [qrShortUrl, setQrShortUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state variable

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button
    console.log(email);
    console.log(phone);
    try {
      const response = await axios.post(
        "https://human-python-backend-4d5fd681de29.herokuapp.com/createInvoice",
        {
          amount: 60000,
          phoneNumber: phone,
          type: "ADMISSIONFEE",
          email: email,
          subscriberId: "1",
        }
      );

      // console.log(response.data);

      setQrText(response.data.qr_text);
      setQrShortUrl(response.data.qPay_shortUrl);
      setLoading(false); // Set loading to false after receiving responses
      setIsSubmitting(false); // Enable the button after receiving responses
    } catch (error) {
      console.error("Error fetching QR code:", error);
      setLoading(false); // Set loading to false in case of error
      setIsSubmitting(false); // Enable the button in case of error
    }
  };

  const renderData = (id) => {
    switch (id) {
      case "highschool":
        return (
          <div>
            <div className="flex flex-col items-start">
              <div className="text-2xl font-bold text-center py-4">
                <Text>Ахлах Сургуулийн Өргөдөл</Text>
              </div>
              <div className="mb-5">
                <Text>
                  Хүмүүн сургуулийн ахлах ангийн /9-12-р анги/ элсэлтийн өргөдөл
                  нь 2 хэсгээс бүрдэнэ.
                </Text>
              </div>
              <div className="font-bold mb-2">
                <Text>Нэгдүгээр шат | Ерөнхий бүртгэл</Text>
              </div>
              <ul className="mb-3 list-disc ml-10">
                <li>
                  <Text>Суралцагчийн ерөнхий мэдээлэл</Text>
                </li>
                <li>
                  <Text>Сурагчийн академик мэдээлэл</Text>
                </li>
                <li>
                  <Text>Гэр бүлийн мэдээлэл</Text>
                </li>
                <li>
                  <Text>Өөрийн илэрхийлэмж эсээ</Text>
                </li>
              </ul>
              <div className="font-bold mb-2">
                <Text>
                  Хоёрдугаар шат | Суралцагчийн ур чадвар болон академик мэдлэг
                </Text>
              </div>
              <ul className="mb-3 list-disc ml-10">
                <li>
                  <Text>Монгол хэл болон математикийн шалгалт</Text>
                </li>
                <li>
                  <Text>
                    Англи хэлний 4 чадамжийн (ярих, сонсох, бичих, унших)
                    шалгуур
                  </Text>
                </li>
                <li>
                  <Text>IQ тест</Text>
                </li>
                <li>
                  <Text>Ярилцлага (Зөвхөн 12-р ангийн суралцагчид)</Text>
                </li>
              </ul>

              <div>
                <Text>
                  Нэгдүгээр шатны өргөдөл бөглөсний дараа өргөдлийн хураамж
                  төлсөн суралцагчийн өргөдлийн элсэлтийн алба хянан шалгаж,
                  дараагийн шатны өргөдлийн холбоосыг мэйл хаягаар илгээнэ.
                </Text>
              </div>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // height: "100vh",
              }}
            >
              <form className="mt-4">
                <div>
                  <strong>
                    <h1>ӨРГӨДЛИЙН ХУРААМЖ ТӨЛӨХ</h1>
                  </strong>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Имэйл хаяг
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Утасны дугаар
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Төлбөрийн Qpay үүсгэж байна..." : "Төлөх"}
                </button>
              </form>
            </div>
          </div>
        );
      case "middleschool":
        return (
          <div>
            <div className="flex flex-col items-start">
              <div className="text-2xl font-bold text-center py-4">
                <Text>Дунд сургуулийн өргөдөл</Text>
              </div>
              <div className="mb-5">
                <Text>
                  Хүмүүн сургуулийн дунд ангийн /6-8-р анги/ элсэлтийн өргөдөл
                  нь 2 хэсгээс бүрдэнэ.
                </Text>
              </div>
              <div className="font-bold mb-2">
                <Text>Нэгдүгээр шат | Ерөнхий бүртгэл</Text>
              </div>
              <ul className="mb-3 list-disc ml-10">
                <li>
                  <Text>Суралцагчийн ерөнхий мэдээлэл</Text>
                </li>
                <li>
                  <Text>Сурагчийн академик мэдээлэл</Text>
                </li>
                <li>
                  <Text>Гэр бүлийн мэдээлэл</Text>
                </li>
                <li>
                  <Text>Өөрийн илэрхийлэмж асуулт, хариулт</Text>
                </li>
              </ul>
              <div className="font-bold mb-2">
                <Text>
                  Хоёрдугаар шат | Суралцагчийн ур чадвар болон академик мэдлэг
                </Text>
              </div>
              <ul className="mb-3 list-disc ml-10">
                <li>
                  <Text>Монгол хэл болон математикийн шалгалт</Text>
                </li>
                <li>
                  <Text>
                    Англи хэлний 4 чадамжийн (ярих, сонсох, бичих, унших)
                    шалгуур
                  </Text>
                </li>
                <li>
                  <Text>IQ тест</Text>
                </li>
              </ul>

              <div>
                <Text>
                  Нэгдүгээр шатны өргөдөл бөглөсний дараа өргөдлийн хураамж
                  төлсөн суралцагчийн өргөдлийн элсэлтийн алба хянан шалгаж,
                  дараагийн шатны өргөдлийн холбоосыг мэйл хаягаар илгээнэ.
                </Text>
              </div>
              {/* <a
              className="button mx-auto mt-5"
              rel="noreferrer"
              href={"https://form.jotform.com/240541695301451"}
              target="_blank"
            >
              Өргөдөл илгээх
            </a> */}
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // height: "100vh",
              }}
            >
              <form className="mt-4">
                <div>
                  <strong>
                    <h1>ӨРГӨДЛИЙН ХУРААМЖ ТӨЛӨХ</h1>
                  </strong>
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Имэйл хаяг
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 text-left"
                  >
                    Утасны дугаар
                  </label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-2 bg-blue-600 text-white font-bold rounded-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Төлбөрийн Qpay үүсгэж байна..." : "Төлөх"}
                </button>
              </form>
            </div>
          </div>
        );
      case "elementaryschool":
        return (
          <div className="flex flex-col items-start">
            <div className="text-2xl font-bold text-center py-4">
              <Text>Бага сургуулийн өргөдөл</Text>
            </div>
            <div className="mb-5">
              <Text>
                Хүмүүн сургуулийн бага ангийн /1-5-р анги/ элсэлтийн өргөдөл нь
                хоёр хэсгээс бүрдэнэ.
              </Text>
            </div>
            <div className="font-bold mb-2">
              <Text>Нэгдүгээр шат | Ерөнхий бүртгэл</Text>
            </div>
            <ul className="mb-3 list-disc ml-10">
              <li>
                <Text>Суралцагчийн ерөнхий мэдээлэл</Text>
              </li>
              <li>
                <Text>Сурагчийн академик мэдээлэл</Text>
              </li>
              <li>
                <Text>Сурагчийн гэр бүлийн мэдээлэл</Text>
              </li>
              <li>
                <Text>Сурагчийн онцлог ба давуу талын мэдээлэл</Text>
              </li>
              <li>
                <Text>Сурагчийн эрүүл мэндийн мэдээлэл</Text>
              </li>

              <li>
                <Text>Хичээлээс гадуурх үйл ажиллагаа</Text>
              </li>

              <li>
                <Text>Эцэг эхийн хандлага, үзэл бодлын талаарх асуулт</Text>
              </li>
            </ul>
            <div className="font-bold mb-2">
              <Text>Хоёрдугаар шат | Суралцагчийн хувь хүний онцлог</Text>
            </div>
            <ul className="mb-3 list-disc ml-10">
              <li>
                <Text>Ур чадварын ажиглалт</Text>
              </li>
              <li>
                <Text>Ерөнхий мэдлэгийн шалгалт</Text>
              </li>
              <li>
                <Text>Сэтгэл зүйн оношилгоо</Text>
              </li>
              <li>
                <Text>
                  Англи хэлний 4 чадамжийн (ярих, сонсох, бичих, унших) шалгуур
                </Text>
              </li>
            </ul>
            <div>
              <Text>
                Нэгдүгээр шатны өргөдөл бөглөсний дараа өргөдлийн хураамж төлсөн
                суралцагчийн өргөдлийн элсэлтийн алба хянан шалгаж, дараагийн
                шатны өргөдлийн холбоосыг мэйл хаягаар илгээнэ.
              </Text>
            </div>
            <a
              className="button mx-auto mt-5"
              rel="noreferrer"
              href={"https://form.jotform.com/240519121158450"}
              target="_blank"
            >
              Өргөдөл илгээх
            </a>
          </div>
        );
      case "kindergarten":
        return (
          <div className="flex flex-col items-start">
            <div className="text-2xl font-bold text-center py-4">
              <Text>Цэцэрлэгийн Өргөдөл</Text>
            </div>
            <div className="mb-3">
              <Text>
                Хүмүүн-Өргөө Цэцэрлэгийн элсэлтийн үйл явц нэг үе шаттай.
              </Text>
            </div>
            <div className="mb-3">
              <Text>
                Суралцагчийн эцэг эх онлайн өргөдөл бөглөх бөгөөд элсэлтийн
                албаны гишүүд дараах хүчин зүйлүүд дээр үндэслэн өргөдлийн
                хариуг (тэнцсэн, тэнцээгүй, хүлээлгийн жагсаалтанд)гаргадаг.
              </Text>
            </div>
            <div className="font-bold mb-2">
              <Text>Нэгдүгээр шат | Ерөнхий бүртгэл</Text>
            </div>
            <ul className="mb-3 list-disc ml-10">
              <li>
                <Text>Хүүхдийн ерөнхий мэдээлэл</Text>
              </li>
              <li>
                <Text>Хүүхдийн гэр бүлийн мэдээлэл</Text>
              </li>
              <li>
                <Text>Хүүхдийн онцлог ба давуу талын мэдээлэл</Text>
              </li>
              <li>
                <Text>Хүүхдийн эрүүл мэндийн мэдээлэл</Text>
              </li>
              <li>
                <Text>Хүүхдийн харилцааны чадвар</Text>
              </li>
              <li>
                <Text>Сонирхол</Text>
              </li>
              <li>Асуулт хариулт</li>
            </ul>
            <div className="font-bold mb-2">
              <Text>
                Доорх зүйлсийг харгалзан үзэж элсэлтийн шийдвэр эцэслэн гарна
              </Text>
              :
            </div>
            <ul className="mb-3 list-disc ml-10">
              <li>
                <Text>
                  Элф сургуулиудын талаар судалсан байдал болон эцэг эхийн
                  зүгээс хүүхдээ элсүүлэл хүсэл эрмэлзлэл
                </Text>
              </li>
              <li>
                <Text>Ерөнхий мэдлэгийн шалгалт</Text>
              </li>
              <li>
                <Text>Эцэг эх болон хүүхэд хоорондын харилцаа хандлага</Text>
              </li>
              <li>
                <Text>Хүүхдийнхээ талаар мэдэж байгаа байдал</Text>
              </li>
            </ul>
            <div>
              <Text>
                Нэгдүгээр шатны өргөдөл бөглөсний дараа өргөдлийн хураамж төлсөн
                суралцагчийн өргөдлийн элсэлтийн алба хянан шалгаж, дараагийн
                шатны өргөдлийн холбоосыг мэйл хаягаар илгээнэ.
              </Text>
            </div>
            <a
              className="button mx-auto mt-5"
              rel="noreferrer"
              href={"https://form.jotform.com/240542978317463"}
              target="_blank"
            >
              Өргөдөл илгээх
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="sm:container sm:mx-auto mx-6">
      {renderData(id)}
      {
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {loading ? (
            <p></p>
          ) : (
            <>
              <br />
              <p>
                Тус өргөдлийн хураамж амжилттай төлөгдсөн үед таны оруулсан
                имайл хаягаар өргөдлийн форм очих болно.
              </p>
              <p>
                Та тус QR кодыг дурын банкны апп-аар уншуулан төлбөрөө төлөөрэй.
              </p>
              <br />
              {qrText && <QRCode value={qrText} size={200} />}
              <br />
              <p>Дүн: ₮60,000</p>
              <br />
              <a href={qrShortUrl} target="_blank" rel="noopener noreferrer">
                <strong>
                  <p>Эсвэл та дарж банкны апп-руугаа үсрээрэй.</p>
                </strong>
              </a>
            </>
          )}
        </div>
      }
    </div>
  );
};

export default Schools;
